import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Index from "../Index";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";
import CircularProgress from "@mui/material/CircularProgress";

const cardName = [
  "Overall Market",
  "Blue Chip",
  "TOP 100",
  "Metaverse",
  "Play 2 Earn",
  "Utility",
  "Passes",
  "DAO",
  "PFP",
];

const NftMarketplace = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState('');
  const [loader, setLoader] = useState(false);
  const navigate = Index.useNavigate();
  const Img = Index.styled("img")({
    padding: "31px",
    width: "100%",
  });
  useEffect(() => {
    setLoader(true);
    DataService.get(Api.Admin.NftMarketData).then((res, err) => {
      setData(res.data.data);
      setLoader(false);
    });
  }, []);
  return (
    <>
      <Index.Box
        sx={{
          background: "#F5F5F5 0% 0% no-repeat padding-box",
          padding: "25px",
        }}
      >
        <Index.Grid container justify="flex-end">
          <Index.Grid item xs>
            <Index.Box
              borderColor="black"
              component="span"
              sx={{
                color: "#1F1F1F",
                padding: "10px 21px 10px 0px",
                fontWeight: "bold",
              }}
              variant="h4"
            >
              NFT MARKET
            </Index.Box>
          </Index.Grid>
        </Index.Grid>
      </Index.Box>
      <Index.Container maxWidth="false">
        <Index.Grid container spacing={3} className="abc">
          {loader && <CircularProgress />}
          {data.map((e, i) => (
            <Index.Grid item xs={6} sm={6} md={3} key={e}>
              <Index.Card
                sx={{ backgroundColor: "#DAEDFE" }}
                elevation={0}
                variant="outlined"
              >
                 <Index.ButtonBase
                      onClick={() => {
                        setId(e._id);
                        navigate(`/users/bluechip/${e._id}`);
                      }}
                    >
                <Img
                  src={
                  e.image ||
                    "http://localhost:3000/static/media/bluechip.716d5236825ad420c463.png"
                  }
                />
                </Index.ButtonBase>
                <Index.Typography
                  sx={{
                    padding: "0px 0px 12px 0px",
                    fontSize: {
                      md: "2.5vh",
                      sm: "3vh",
                      lg: "2.5vh",
                      xs: "3vh",
                    },
                  }}
                  variant="h5"
                  align="center"
                >
                  {e.title || "TestNFT"}
                </Index.Typography>
              </Index.Card>
            </Index.Grid>
          ))}
        </Index.Grid>
      </Index.Container>
    </>
  );
};
export default NftMarketplace;
