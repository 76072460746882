const Api = {
  Admin: {
    userList: "userList",
    getSocialLinks:"socialLinks",
    updateWebsiteLinksList:"updateWebsiteLinksList",
    NotificationCreate:"NotificationCreate",
    notificationList:"notificationList",
    Login:"login",
    CategoryCreate:"categoryCreate",
    CategoryManagementList:"categoryList",
    ProjectCategoryCreate:"projectCategoryCreate",
    ProjectCategoryList:"projectCategoryList",
    ProjectCategoryById:"projectCategoryById",
    CategoryById:"categoryById",
    DeleteCategory:"deleteCategory",
    DeleteProjectCategory:"deleteProjectCategory",
    NewsList:"newsList",
    NewsById:"newsById",
    NewsCreateUpdate:"newsCreateUpdate",
    DeleteNews:"deleteNews",
    CollectionList:"collectionList",
    CollectionById:"collectionById",
    CollectionCreate:"collectionCreate",
    DeleteCollection:"deleteCollection",
    CreateUpdateCMS:"createUpdateCMS",
    CMSById:"CMSById",
    ProjectCreate:"projectCreate",
    ProjectList:"projectList",
    ProjectById:"projectById",
    DeleteProject:"deleteProject",
    GetAllSlugList:"getAllSlugList",
    AssetsData: "assets",
    CollectionsData: "collections",
    NftMarketData : "getNftMarketData",
    CategoryWiseProject : "category-wise-project",
    SingleProjectDetails : "get-single-project-detail"
  },
};
export { Api };
